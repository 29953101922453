.sc-gKHVLF{
  display:none
} 
.login {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .login .div {
    background-color: #ffffff;
    border: 1px none;
    height: 135%;
    position: relative;
    width: 100%;
  }
  
  .login .group {
    /* height: 629px; */
    left: 10%;
    position: absolute;
    top: 100px;
    width: 503px;
  }
  
  .login .text-wrapper {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 27px;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;
    position: absolute;
    top: 68px;
    white-space: nowrap;
  }

  .login .text-wrapper-sign {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 27px;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;
    position: absolute;
    top: 60px;
    white-space: nowrap;
  }
  
  .login .p {
    color: #adadad;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 52px;
    left: 0;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    top: 113px;
    width: 290px;
  }
  
  .login .h-1 {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 27px;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .login .group-2 {
    /* height: 422px; */
    left: 0;
    position: absolute;
    top: 207px;
    width: 499px;
  }

  .login .group-2_sign {
    /* height: 422px; */
    left: 0;
    position: relative;
    top: 100px;
    width: 499px;
  }
  
  .login .input-filled {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: absolute;
    top: 92px;
    width: 497px;
  }

  .login .input-filled_sign {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: relative;
    top: 220px;
    width: 497px;
  }
  
  .login .overlap1 {
    height: 50px;
    position: relative;
    width: 500px;
  }

  .login .overlap2 {
    height: 60px;
    position: relative;
    width: 500px;
  }

  .login .overlap3 {
    height: 90px;
    position: relative;
    width: 500px;
  }
  
  .login .input-BG {
    border-radius: 8px;
    box-shadow: var(--nav-bot);
    height: 50px;
    left: 0;
    position: relative;
    top: 0;
    width: 300px;
    color: #9e9e9e;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
  }
  
  .login .placeholder {
    color: #9e9e9e;
    font-family: "Open Sans", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 51px;
    left: 25px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 10px;
    width: 475px;
  }
  
  .login .feather-icon-eye-off {
    height: 24px;
    left: 457px;
    position: absolute;
    top: 15px;
    width: 24px;
  }
  
  .login .shape {
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }
  
  .login .img {
    height: 18px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 24px;
  }
  
  .login .size {
    height: 24px;
    /* left: 5838px; */
    position: absolute;
    /* top: 1076px; */
    width: 24px;
  }
  
  .login .overlap-wrapper {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 497px;
  }

  .login .overlap-wrapper1 {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: relative;
    top: 0;
    width: 497px;
  }
  .login .overlap-wrapper2 {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: relative;
    top: 0;
    width: 497px;
  }
  
  .login .primary {
    background-color: #7c3a8e;
    border-radius: 6px;
    box-shadow: var(--dropshadow-1);
    height: 50px;
    left: 0;
    position: absolute;
    top: 220px;
    width: 497px;
  }
  
  .login .continue {
    color: #ffffff;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    left: 70px;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    text-align: center;
    top: 175px;
    width: 150px;
    border-radius: 5px;
  }
  .login .continue_sign {
    color: #ffffff;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    left: 70px;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    text-align: center;
    top: 75px;
    width: 150px;
    border-radius: 5px;
  }
  
  .login .text-wrapper-2 {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 40px;
    left: 36px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 240px;
    width: 180px;
  }

  .login .text-wrapper-5 {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 40px;
    left: 156px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 240px;
    width: 180px;
    cursor: pointer;
  }
  
  .login .element-forms-elements {
    height: 24px;
    left: 0;
    position: absolute;
    top: 240px;
    width: 24px;
  }
  
  .login .ic-check-box {
    height: 18px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 18px;
  }
  
  .login .left {
    /* height: 1080px; */
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }
  
  .login .frame {
    height: 85px;
    /* left: 55%; */
    position: absolute;
    top: 72px;
    width: 216px;
  }

  .label {
    border: 0px none;
    height: 51px;
    width: 475px;
}

.label .placeholder {
    color: #9e9e9e;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 51px;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: fixed;
    top: 0;
    width: 475px;
}

.login .continue a{
  text-decoration: none;
  color:#ffffff;
}

/* Add margin to the web borders */
body {
  margin: 0;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* background-color: #f8f9fa; */
}

.div {
  position: relative;
  width: 100%;
  max-width: 360px;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  margin: 20px; /* Add margin here */
  top: 40px;
}

.signin_admin{
  position: relative;
  margin-top: 85px;
}

.resign_admin{
 cursor: pointer;
}
.sc-gKHVLF{
  display:none
}

.error_message{
  color: red;
}


.pass_show{
  margin-left: 87%;
  margin-top: 55px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px;
}


/* styles.css */

.data_margin {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.data_margin div {
  margin-bottom: 10px;
}

.data_margin label {
  font-weight: bold;
  margin-right: 10px;
}

.data_margin .date-picker-container {
  /* display: flex; */
  align-items: center;
  margin-bottom: 10px;
}

.data_margin .date-picker-container label {
  flex: 0 0 100px; /* Fixed width for labels */
}

.data_margin .date-picker-container .react-datepicker-wrapper {
  flex: 1;
}
 
.data_margin .reve_bn {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.data_margin .reve_bn:hover {
  background-color: #0056b3;
}

.data_margin .data-table {
  margin-top: 20px;
}

.data_margin .data-table .rdt_TableHead {
  background-color: #f8f9fa;
  font-weight: bold;
}

.data_margin .data-table .rdt_TableBody {
  border: 1px solid #dee2e6;
}

.data_margin .data-table .rdt_TableCell {
  padding: 10px;
}


