
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);
@import url('https://fonts.googleapis.com/css?family=Roboto');




.react-responsive-modal-root h3{
  color: #4f2564;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 30px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  /* position: absolute; */
  top: 21px;
  white-space: nowrap;
}


.react-responsive-modal-root h2 {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  line-height: 1;
  color: #454cad;
  margin-bottom: 0;
}
.react-responsive-modal-root p {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #5f6982;
}
/* // Upload Demo
//  */
.react-responsive-modal-root .uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.react-responsive-modal-root label {
    float: left;
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 7px;
    border: 3px solid #eee;
    transition: all .2s ease;
    user-select: none;

    &:hover {
      border-color: #454cad;
    }
    &.hover {
      border: 3px solid #454cad;
      box-shadow: inset 0 0 0 6px #eee;
      
      #start {
        i.fa {
          transform: scale(0.8);
          opacity: 0.3;
        }
      }
    }
  }

  .react-responsive-modal-root #start {
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
    i.fa {
      font-size: 50px;
      margin-bottom: 1rem;
      transition: all .2s ease-in-out;
    }
  }
  .react-responsive-modal-root #response {
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
    .react-responsive-modal-root #messages {
      margin-bottom: .5rem;
    }
  }

  .react-responsive-modal-root #file-image {
    display: inline;
    margin: 0 auto .5rem auto;
    width: auto;
    height: auto;
    max-width: 180px;
    &.hidden {
      display: none;
    }
  }
  
  .react-responsive-modal-root #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
    &.hidden {
      display: none;
    }
  }

   .react-responsive-modal-root progress,
  .progress {
   
    display: inline;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background-color: #eee;
    overflow: hidden;
  }

  .react-responsive-modal-root .progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: #eee;
  }

  .react-responsive-modal-root .progress[value]::-webkit-progress-value {
    background: linear-gradient(to right, darken(#454cad,8%) 0%, #454cad 50%);
    border-radius: 4px; 
  }
  .react-responsive-modal-root .progress[value]::-moz-progress-bar {
    background: linear-gradient(to right, darken(#454cad,8%) 0%, #454cad 50%);
    border-radius: 4px; 
  }

  .react-responsive-modal-root input[type="file"] {
    display: none;
  }

  .react-responsive-modal-root  div {
    margin: 0 0 .5rem 0;
    color: #5f6982;
  }
  .react-responsive-modal-root .btn {
    display: inline-block;
    margin: .5rem .5rem 1rem .5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    border: none;
    border-radius: .2rem;
    outline: none;
    padding: 0 1rem;
    height: 36px;
    line-height: 36px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    background: #7c3a8e;
    border-color: #7c3a8e;
    cursor: pointer;
  }
